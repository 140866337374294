import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import ProductHero from '../../components/product-hero';
import ProductTable from '../../components/product-table';

const ProductOne = () => {
  const { tablero } = useStaticQuery(graphql`
    query {
      tablero: file(relativePath: { eq: "tablero-calidad-induba.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="max-w-6xl mx-auto my-10 px-4 xl:px-0">
        <ProductHero
          title="Tableros"
          description="Tablero de madera de pino finger joint lijado (con o sin nudo)."
          uses="Muebles o partes de muebles (camas, placares, mesas, puertas, escritorios)."
          isEnglish={false}
        >
          <Img
            className="portrait"
            fluid={tablero.childImageSharp.fluid}
            fadeIn
          />
        </ProductHero>

        <ProductTable isEnglish={false}>
          <tbody>
            <tr>
              <td className="p-3 align-top" rowSpan="6">
                Talberos de Pino
              </td>
              <td className="p-3">3.05m Largo x 1.22m Ancho</td>
              <td className="p-3">10mm, 15mm, 18mm, 21mm</td>
            </tr>
          </tbody>
        </ProductTable>
      </div>
    </Layout>
  );
};

export default ProductOne;
